<template>
  <div>
    <div id="mapContainer"></div>
  </div>
</template>

<script>
// import "leaflet-draw";
// import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
export default {
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.set_map2();
  },
  methods: {
    // async get_rack() {
    //   let vm = this;
    //   let rack = await vm.$axios.post("masterRack/list", {
    //     gudang_id: vm.gudang_id,
    //   });
    //   // console.log(vm.gudang, rack.data.data, "rack");
    //   if (rack.data.status == 200) {
    //     if (rack.data.data.length) {
    //       vm.rack_options = rack.data.data.map((item) => {
    //         // console.log(item);
    //         item.label =
    //           item.nama_area_gudang +
    //           " - " +
    //           item.nama_rack +
    //           " - " +
    //           item.tipe_rack;
    //         return item;
    //       });
    //     } else {
    //       vm.rack_options = [];
    //     }
    //   } else {
    //     vm.rack_options = [];
    //   }

    //   let kategori = await vm.$axios.post("masterKategori/list");
    //   // console.log(kategori.data.data, "kategori");
    //   if (kategori.data.status == 200) {
    //     if (kategori.data.data.length) {
    //       vm.kategori_options = kategori.data.data;
    //     } else {
    //       vm.kategori_options = [];
    //     }
    //   } else {
    //     vm.kategori_options = [];
    //   }
    // },
    // async clear_poligon() {
    //   // console.log("eraser");
    //   let vm = this;
    //   if (vm.map) {
    //     let eraser = new Promise((resolve) => {
    //       vm.poligons.forEach((ele, idx) => {
    //         if (ele.gj) {
    //           vm.map.removeLayer(ele.gj);
    //         }
    //         if (idx === vm.poligons.length - 1) resolve();
    //       });
    //     });
    //     eraser.then(() => {
    //       this.get_poligon();
    //     });
    //   }
    // },
    // async get_poligon() {
    //   let vm = this;
    //   let poligons = await vm.$axios.post("slot/list", vm.data_fetch);
    //   // console.log(poligons.data.data, "poli");
    //   if (poligons.data.data.length) {
    //     vm.poligons = poligons.data.data.map((item) => {
    //       item.polystyle = vm.polystyle;
    //       if (item.difficulty == "easy") {
    //         item.polystyle.fillColor = "#219653";
    //         item.polystyle.color = "#219653";
    //       } else if (item.difficulty == "moderate") {
    //         item.polystyle.fillColor = "#2F80ED";
    //         item.polystyle.color = "#2F80ED";
    //       } else if (item.difficulty == "hard") {
    //         item.polystyle.fillColor = "#C12727";
    //         item.polystyle.color = "#C12727";
    //       } else if (item.difficulty == null) {
    //         item.polystyle.fillColor = "#FFFFFF";
    //         item.polystyle.color = "#219653";
    //       }
    //       if (item.polygon) {
    //         // console.log(item);
    //         let gj = new L.geoJSON(item.polygon, { style: item.polystyle });
    //         item.gj = gj;
    //         gj.addTo(vm.map);
    //         gj.eachLayer(function (layer) {
    //           layer.bindTooltip(item.kode_slot, {
    //             permanent: true,
    //             direction: "center",
    //             className: "tooltips",
    //             opacity: 1,
    //           });
    //           layer.on("click", function () {
    //             vm.data_slot = item;
    //             vm.update = !vm.update;
    //           });
    //         });
    //       }
    //       return item;
    //     });
    //   }
    // },
    async set_map2() {
      let vm = this;
      if (vm.map) {
        vm.map.remove();
      }
      vm.map = L.map("mapContainer", {
        center: [-7.0247226, 110.3346633],
        zoom: 16,
      });
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: "",
      }).addTo(vm.map);

      // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", [
      //   [0, 0],
      //   [1000, 1000],
      // ]).addTo(vm.map);
    },
    async set_map() {
      let vm = this;
      if (vm.map) {
        vm.map.remove();
      }
      let denah = await vm.$axios(`gudang/detailsById/${vm.gudang_id}`);
      let template_map = denah.data.data[0].url;
      var customCRS = L.extend(L.CRS.Simple);
      var map = L.map("mapContainer", {
        crs: customCRS,
      }).setView([500, 500], 1);
      vm.map = map;
      vm.map.options.minZoom = 0;
      vm.map.options.maxZoom = 2;
      // L.imageOverlay("https://serova.id/foto/denah.png", [
      // 	[0, 0],
      // 	[1000, 1000],
      // ]).addTo(map);
      L.imageOverlay(template_map, [
        [0, 0],
        [1000, 1000],
      ]).addTo(map);
      // var prevZoom = map.getZoom();
      // vm.map.on("zoomend", function (e) {
      //   var zoomLevel = e.target._zoom;
      //   var tooltip = $(".leaflet-tooltip");
      //   let zoomnow = zoomLevel - prevZoom;
      //   // console.log(zoomnow)
      //   switch (zoomnow) {
      //     case -2:
      //       tooltip.css("font-size", 6);
      //       tooltip.css("color", "yellow");
      //       break;
      //     case -1:
      //       tooltip.css("font-size", 8);
      //       tooltip.css("color", "yellow");
      //       break;
      //     case 0:
      //       tooltip.css("font-size", 10);
      //       tooltip.css("color", "yellow");
      //       break;
      //     case 1:
      //       tooltip.css("font-size", 12);
      //       tooltip.css("color", "yellow");
      //       break;
      //     case 2:
      //       tooltip.css("font-size", 14);
      //       tooltip.css("color", "yellow");
      //       break;
      //     case 3:
      //       tooltip.css("font-size", 16);
      //       tooltip.css("color", "yellow");
      //       break;
      //     default:
      //       tooltip.css("color", "yellow");
      //       tooltip.css("font-size", 10);
      //       break;
      //   }
      // });
      // vm.map.on("zoomstart", function (e) {
      //     console.log(e.target._zoom);
      //     var zoom = e.target._zoom;
      //     if (vm.map.hasLayer(e)) {
      //         e.eachLayer(function (layer) {
      //             console.log(layer);
      //             if (zoom >= 12 && !layer.getTooltip()) {
      //                 let tooltiptext = "<b>" + layer.feature.properties.ZCTA5CE10 + "</b>";
      //                 layer.bindTooltip(tooltiptext, { sticky: false, permanent: true, interactive: false, direction: "center", className: "zipcodes" });
      //             } else if (zoom < 12 && layer.getTooltip()) {
      //                 //console.log('remove tooltip');
      //                 layer.unbindTooltip();
      //             }
      //         });
      //     }
      // });
      map.attributionControl.setPrefix("");
      var drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);
      var drawControl = new L.Control.Draw({
        draw: {
          polygon: false,
          marker: false,
          polyline: false,
          circle: false,
          circlemarker: false,
        },
        position: "topright",
        edit: {
          featureGroup: drawnItems,
        },
      });
      map.addControl(drawControl);

      map.on(L.Draw.Event.CREATED, function (event) {
        if (drawnItems) {
          drawnItems.clearLayers();
        }
        var layer = event.layer;
        // console.log(vm.konversi(layer));
        drawnItems.addLayer(layer);
        vm.poligon = vm.konversi(layer);
        vm.define = !vm.define;
      });
    },
    // konversi(layer) {
    //   var lng,
    //     lat,
    //     coords = [];
    //   if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
    //     var latlngs = layer.getLatLngs();
    //     for (var i = 0; i < latlngs.length; i++) {
    //       var latlngs1 = latlngs[i];
    //       if (latlngs1.length) {
    //         for (var j = 0; j < latlngs1.length; j++) {
    //           coords.push(latlngs1[j].lng + " " + latlngs1[j].lat);
    //           if (j === 0) {
    //             lng = latlngs1[j].lng;
    //             lat = latlngs1[j].lat;
    //           }
    //         }
    //       } else {
    //         coords.push(latlngs[i].lng + " " + latlngs[i].lat);
    //         if (i === 0) {
    //           lng = latlngs[i].lng;
    //           lat = latlngs[i].lat;
    //         }
    //       }
    //     }
    //     if (layer instanceof L.Polygon) {
    //       return "POLYGON((" + coords.join(",") + "," + lng + " " + lat + "))";
    //     } else if (layer instanceof L.Polyline) {
    //       return "LINESTRING(" + coords.join(",") + ")";
    //     }
    //   } else if (layer instanceof L.Marker) {
    //     return (
    //       "POINT(" + layer.getLatLng().lng + " " + layer.getLatLng().lat + ")"
    //     );
    //   }
    // },
    alert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.clear_poligon();
    },
  },
};
</script>

<style lang="scss" scoped>
#mapContainer {
  width: 100%;
  height: 500px;
  background-color: white;
  border: 1px solid black;
}
</style>
