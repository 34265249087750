import { render, staticRenderFns } from "./grafik_bar_grid_poli.vue?vue&type=template&id=0f88c786&scoped=true"
import script from "./grafik_bar_grid_poli.vue?vue&type=script&lang=js"
export * from "./grafik_bar_grid_poli.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f88c786",
  null
  
)

export default component.exports