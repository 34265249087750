<template>
  <CContainer fluid>
    <CRow class="row d-flex justify-content-between display-card">
      <CCol class="col-lg-2 col-md-6 px-2">
        <CCard color="primary" style="fill: #2ecc71">
          <CCardBody
            ><CRow
              ><CCol md="8"
                ><h3>{{ pasien_terdaftar }}</h3>
                <div>Pasien Terdaftar</div></CCol
              ><CCol md="4" align="right"><CIcon name="cil-user" size="xl"></CIcon></CCol></CRow
          ></CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-2 col-md-6 px-2">
        <CCard color="primary" style="fill: #2ecc71">
          <CCardBody
            ><CRow
              ><CCol md="8"
                ><h3>{{ pasien_baru }}</h3>
                <div>Pasien Baru</div></CCol
              ><CCol md="4" align="right"><CIcon name="cil-people" size="xl"></CIcon></CCol></CRow
          ></CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-2 col-md-6 px-2">
        <CCard color="primary" style="fill: #2ecc71">
          <CCardBody
            ><CRow
              ><CCol md="8"
                ><h3>{{ kunjungan_loket }}</h3>
                <div>Kunjungan Loket</div></CCol
              ><CCol md="4" align="right"><CIcon name="cil-wc" size="xl"></CIcon></CCol></CRow
          ></CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-2 col-md-6 px-2">
        <CCard color="primary" style="fill: #2ecc71">
          <CCardBody
            ><CRow
              ><CCol md="8"
                ><h3>{{ kunjungan_bpjs }}</h3>
                <div>Kunjungan BPJS</div></CCol
              ><CCol md="4" align="right"><CIcon name="cil-user" size="xl"></CIcon></CCol></CRow
          ></CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-2 col-md-6 px-2">
        <CCard color="primary" style="fill: #2ecc71">
          <CCardBody
            ><CRow
              ><CCol md="8"
                ><h3>{{ kunjungan_bulan_ini }}</h3>
                <div>Kunjungan Bulan Ini</div></CCol
              ><CCol md="4" align="right"><CIcon icon="cil-book" size="xl"></CIcon></CCol></CRow
          ></CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Pengguna -->
    <CRow>
      <CCol md="12">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Pertumbuhan Pengguna</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="6"> <Bargrid :datasetx="dataPengguna" judul="Total Pengguna" :backgroundColor="backgroundColor" /></b-col>
                <b-col cols="6"><Map></Map></b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </CCol>
    </CRow>
    <!-- Kunjungan -->
    <b-row>
      <b-col cols="6" md="6" lg="6">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().format("YYYY") }} Berdasarkan Jenis</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"><Linewave :datasetx="dataJenis" :judul="''" :backgroundColor="backgroundColor" /></b-col>
          </b-row> </b-card
      ></b-col>
      <b-col cols="6" md="6" lg="6"
        ><b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().format("YYYY") }} Berdasarkan Asuransi</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"><BargridAsuransi :datasetx="dataAsuransi" :judul="''" :backgroundColor="backgroundColor" /> </b-col>
          </b-row> </b-card
      ></b-col>
    </b-row>
    <!-- Diagnosa -->
    <b-row>
      <b-col cols="6" md="6" lg="6">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kasus Diagnosa Terbanyak {{ $moment().format("MMMM YYYY") }}</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"> <Diagnosa :dataset="diagnosa" :fields="diagnosaFields" /> </b-col>
          </b-row> </b-card
      ></b-col>
      <b-col cols="6" md="6" lg="6"
        ><b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Penggunaan Obat Terbanyak {{ $moment().format("MMMM YYYY") }}</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"> <Obat :dataset="obat" :fields="obatFields" /> </b-col>
          </b-row> </b-card
      ></b-col>
    </b-row>
    <!-- Tanggal -->
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().subtract(1, "month").format("MMMM YYYY") }} - {{ $moment().format("MMMM YYYY") }} Berdasarkan Tanggal</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"> <Linewave :datasetx="dataTahun" :judul="'Kunjungan'" :backgroundColor="backgroundColor" /></b-col>
          </b-row> </b-card
      ></b-col>
    </b-row>
    <!-- Ruangan -->
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().subtract(1, "month").format("MMMM YYYY") }} - {{ $moment().format("MMMM YYYY") }} Berdasarkan Umur</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="6">
              <PolarArea :datasetx="dataUmur" :judul="'Pasien'" :backgroundColor="backgroundColor" />
            </b-col>
            <b-col cols="6">
              <b-row>
                <h4>Statistik pasien</h4>
                <b-col md="12" lg="12" sm="12">
                  <b-card>
                    <b-row>
                      <b-col>
                        <b-form-group label-cols-md="3" label-cols-sm="12">
                          <template v-slot:label> <h5>Jenis Kelamin</h5> </template>
                          <b-form-select v-model="data.jenis_kelamin" :options="optionJenisKelamin"></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col></b-col>
                      <b-col md="2" lg="2" sm="6"><b-button block variant="primary" @click="get_statistik()"> Filter</b-button></b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col cols="12" v-for="(usia, idx) in dataUmur.labels" :key="usia">
                  <b-row class="mt-1">
                    <b-col cols="1" md="1" lg="1"><div :style="`width: 15px; height: 15px; background-color: ${backgroundColor[idx][0]}; border-radius: 5px`"></div></b-col>
                    <b-col cols="8" md="8" lg="8">
                      <h5>{{ usia }}</h5>
                    </b-col>
                    <b-col cols="1" md="1" lg="1">
                      <h5>{{ dataUmur.datasets[0].data[idx] }}</h5>
                    </b-col>
                    <b-col cols="2" md="2" lg="2">
                      <h5>( {{ dataUmur.datasets[0].persen[idx] }} %)</h5>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Poli -->
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().subtract(1, "month").format("MMMM YYYY") }} - {{ $moment().format("MMMM YYYY") }} Berdasarkan Poli</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"><BargridPoli :datasetx="dataPoli" :judul="'Kunjungan'" :backgroundColor="backgroundColor" /> </b-col>
          </b-row> </b-card
      ></b-col>
    </b-row>
    <!-- Faskes -->
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().subtract(1, "month").format("MMMM YYYY") }} - {{ $moment().format("MMMM YYYY") }} Berdasarkan Faskes</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"><BargridFaskes :datasetx="dataFaskes" :judul="'Kunjungan'" :backgroundColor="backgroundColor" /> </b-col>
          </b-row> </b-card
      ></b-col>
    </b-row>
    <!-- Wilayah -->
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Tahun {{ $moment().subtract(1, "month").format("MMMM YYYY") }} - {{ $moment().format("MMMM YYYY") }} Berdasarkan Wilayah</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12"> <BargridWilayah :datasetx="dataWilayah" :judul="'Kunjungan'" :backgroundColor="backgroundColor" /></b-col
          ></b-row>
        </b-card>
      </b-col>
    </b-row>
  </CContainer>
</template>

<script>
import Bargrid from "../components/Grafik/grafik_bar_grid.vue";
import BargridAsuransi from "../components/Grafik/grafik_bar_grid_asuransi.vue";
import BargridPoli from "../components/Grafik/grafik_bar_grid_poli.vue";
import BargridFaskes from "../components/Grafik/grafik_bar_grid_faskes.vue";
import BargridWilayah from "../components/Grafik/grafik_bar_grid_wilayah.vue";
import Linewave from "../components/Grafik/grafik_wave.vue";
import Map from "../components/Map/dashboard_map.vue";
import Diagnosa from "../components/Table/diagnosa.vue";
import Obat from "../components/Table/obat.vue";
import PolarArea from "../components/Grafik/grafik_donut";
export default {
  name: "Dashboard",
  components: {
    Bargrid,
    BargridAsuransi,
    BargridPoli,
    BargridFaskes,
    BargridWilayah,
    Linewave,
    Map,
    Diagnosa,
    Obat,
    PolarArea,
  },
  data() {
    return {
      bulan: null,
      kunjungan_loket: null,
      kunjungan_faskes: null,
      kunjungan_wilayah: null,
      kunjungan_bpjs: null,
      kunjungan_poli: null,
      kunjungan_bulan_ini: null,
      pasien_terdaftar: null,
      pasien_baru: null,
      pengguna: null,
      kunjungan_asuransi: null,
      diagnosa: [],
      obat: [],
      kunjungan_jenis: null,
      backgroundColor: [
        [
          "rgba(46, 204, 113, 1)",
          "rgba(46, 204, 113, 0.9)",
          "rgba(46, 204, 113, 0.8)",
          "rgba(46, 204, 113, 0.7)",
          "rgba(46, 204, 113, 0.6)",
          "rgba(46, 204, 113, 0.5)",
          "rgba(46, 204, 113, 0.4)",
          "rgba(46, 204, 113, 0.2)",
          "rgba(46, 204, 113, 0.2)",
          "rgba(46, 204, 113, 0.1)",
          "rgba(46, 204, 113, 0)",
        ],
        [
          "rgba(213, 47, 101, 1)",
          "rgba(213, 47, 101, 0.9)",
          "rgba(213, 47, 101, 0.8)",
          "rgba(213, 47, 101, 0.7)",
          "rgba(213, 47, 101, 0.6)",
          "rgba(213, 47, 101, 0.5)",
          "rgba(213, 47, 101, 0.4)",
          "rgba(213, 47, 101, 0.3)",
          "rgba(213, 47, 101, 0.2)",
          "rgba(213, 47, 101, 0.1)",
          "rgba(213, 47, 101, 0)",
        ],
        [
          "rgba(64, 90, 156, 1)",
          "rgba(64, 90, 156, 0.9)",
          "rgba(64, 90, 156, 0.8)",
          "rgba(64, 90, 156, 0.7)",
          "rgba(64, 90, 156, 0.6)",
          "rgba(64, 90, 156, 0.5)",
          "rgba(64, 90, 156, 0.4)",
          "rgba(64, 90, 156, 0.3)",
          "rgba(64, 90, 156, 0.2)",
          "rgba(64, 90, 156, 0.1)",
          "rgba(64, 90, 156, 0)",
        ],
        [
          "rgba(170, 213, 47, 1)",
          "rgba(170, 213, 47, 0.9)",
          "rgba(170, 213, 47, 0.8)",
          "rgba(170, 213, 47, 0.7)",
          "rgba(170, 213, 47, 0.6)",
          "rgba(170, 213, 47, 0.5)",
          "rgba(170, 213, 47, 0.4)",
          "rgba(170, 213, 47, 0.3)",
          "rgba(170, 213, 47, 0.2)",
          "rgba(170, 213, 47, 0.1)",
          "rgba(170, 213, 47, 0)",
        ],
        [
          "rgba(124, 124, 124, 1)",
          "rgba(124, 124, 124, 0.9)",
          "rgba(124, 124, 124, 0.8)",
          "rgba(124, 124, 124, 0.7)",
          "rgba(124, 124, 124, 0.6)",
          "rgba(124, 124, 124, 0.5)",
          "rgba(124, 124, 124, 0.4)",
          "rgba(124, 124, 124, 0.3)",
          "rgba(124, 124, 124, 0.2)",
          "rgba(124, 124, 124, 0.1)",
          "rgba(124, 124, 124, 0)",
        ],
        [
          "rgba(43, 52, 153, 1)",
          "rgba(43, 52, 153, 0.9)",
          "rgba(43, 52, 153, 0.8)",
          "rgba(43, 52, 153, 0.7)",
          "rgba(43, 52, 153, 0.6)",
          "rgba(43, 52, 153, 0.5)",
          "rgba(43, 52, 153, 0.4)",
          "rgba(43, 52, 153, 0.3)",
          "rgba(43, 52, 153, 0.2)",
          "rgba(43, 52, 153, 0.1)",
          "rgba(43, 52, 153, 0)",
        ],
        [
          "rgba(255, 108, 34, 1)",
          "rgba(255, 108, 34, 0.9)",
          "rgba(255, 108, 34, 0.8)",
          "rgba(255, 108, 34, 0.7)",
          "rgba(255, 108, 34, 0.6)",
          "rgba(255, 108, 34, 0.5)",
          "rgba(255, 108, 34, 0.4)",
          "rgba(255, 108, 34, 0.3)",
          "rgba(255, 108, 34, 0.2)",
          "rgba(255, 108, 34, 0.1)",
          "rgba(255, 108, 34, 0)",
        ],
        [
          "rgba(255, 146, 9, 1)",
          "rgba(255, 146, 9, 0.9)",
          "rgba(255, 146, 9, 0.8)",
          "rgba(255, 146, 9, 0.7)",
          "rgba(255, 146, 9, 0.6)",
          "rgba(255, 146, 9, 0.5)",
          "rgba(255, 146, 9, 0.4)",
          "rgba(255, 146, 9, 0.3)",
          "rgba(255, 146, 9, 0.2)",
          "rgba(255, 146, 9, 0.1)",
          "rgba(255, 146, 9, 0)",
        ],
        [
          "rgba(255, 208, 153, 1)",
          "rgba(255, 208, 153, 0.9)",
          "rgba(255, 208, 153, 0.8)",
          "rgba(255, 208, 153, 0.7)",
          "rgba(255, 208, 153, 0.6)",
          "rgba(255, 208, 153, 0.5)",
          "rgba(255, 208, 153, 0.4)",
          "rgba(255, 208, 153, 0.3)",
          "rgba(255, 208, 153, 0.2)",
          "rgba(255, 208, 153, 0.1)",
          "rgba(255, 208, 153, 0)",
        ],
        [
          "rgba(85, 124, 85, 1)",
          "rgba(85, 124, 85, 0.9)",
          "rgba(85, 124, 85, 0.8)",
          "rgba(85, 124, 85, 0.7)",
          "rgba(85, 124, 85, 0.6)",
          "rgba(85, 124, 85, 0.5)",
          "rgba(85, 124, 85, 0.4)",
          "rgba(85, 124, 85, 0.3)",
          "rgba(85, 124, 85, 0.2)",
          "rgba(85, 124, 85, 0.1)",
          "rgba(85, 124, 85, 0)",
        ],
        [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 0.9)",
          "rgba(255, 255, 255, 0.8)",
          "rgba(255, 255, 255, 0.7)",
          "rgba(255, 255, 255, 0.6)",
          "rgba(255, 255, 255, 0.5)",
          "rgba(255, 255, 255, 0.4)",
          "rgba(255, 255, 255, 0.3)",
          "rgba(255, 255, 255, 0.2)",
          "rgba(255, 255, 255, 0.1)",
          "rgba(255, 255, 255, 0)",
        ],
      ],
      dataPengguna: {
        labels: [],
        datasets: [
          {
            label: "Pengguna",
            data: [],
          },
        ],
      },
      data: {
        jenis_kelamin: null,
      },
      optionJenisKelamin: [
        { value: null, text: "Semua" },
        { value: "L", text: "laki=laki" },
        { value: "P", text: "Perempuan" },
      ],
      dataUmur: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
          },
        ],
      },
      dataJenis: {
        labels: [],
        datasets: [
          {
            label: "Baru",
            data: [],
          },
          {
            label: "Lama",
            data: [],
          },
        ],
      },
      dataAsuransi: {
        labels: [],
        datasets: [
          {
            label: "Umum",
            data: [],
          },
          {
            label: "BPJS",
            data: [],
          },
          {
            label: "Lain-lain",
            data: [],
          },
        ],
      },
      dataTahun: {
        labels: [],
        datasets: [
          {
            label: "Kunjungan",
            data: [],
          },
        ],
      },
      // dataRuang: {
      //   labels: ["Umum", "MTBS", "KIA", "Gigi", "Lansia", "Anak", "KB", "Rawat Jalan", "RGD", "Paru", "TB", "Prolanis", "Inap", "Jiwa", "UGD", "Lain-lain"],
      //   datasets: [
      //     {
      //       label: "Kunjungan",
      //       data: [78, 52, 41, 50, 120, 77, 28, 49, 36, 94, 73, 31, 236, 223, 675, 123, 341, 345],
      //     },
      //   ],
      // },
      dataPoli: {
        labels: [],
        datasets: [
          {
            label: "Kunjungan",
            data: [],
          },
        ],
      },
      diagnosaFields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "kd_diag",
          label: "Kode",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nm_diag",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "",
          label: "",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "",
          label: "",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      obatFields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "",
          label: "",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "",
          label: "",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      dataFaskes: {
        labels: [],
        datasets: [
          {
            label: "Kunjungan",
            data: [],
          },
        ],
      },
      dataWilayah: {
        labels: [],
        datasets: [
          {
            label: "Kunjungan",
            data: [],
          },
        ],
      },
    };
  },
  created() {
    this.bulan = this.$moment();
    this.get_statistik();
    this.get_kunjungan();
    this.get_kunjungan_asuransi();
    this.get_kunjungan_jenis();
    this.get_diagnosa();
    this.get_obat();
    this.get_kunjungan_harian();
    this.get_kunjungan_faskes();
    this.get_kunjungan_wilayah();
    this.get_kunjungan_poli();
    this.get_pengguna()
  },
  methods: {
    async get_statistik() {
      let vm = this;

      let umur = await vm.$axios.post("anamnesa/laporan_statistik_pasien", vm.data);
      // console.log(umur.data.data);
      let umurs = umur.data.data[0].grafik_pasien_menurut_umur;
      vm.dataUmur = {
        labels: [
          "Balita (0 - 5 Tahun)",
          "Kanak-Kanak (6 - 11 Tahun)",
          "Remaja Awal (12 - 16 Tahun)",
          "Remaja Akhir (17 - 25 Tahun)",
          "Dewasa Awal (26 - 35 Tahun)",
          "Dewasa Akhir (36 - 45 Tahun)",
          "Lansia Awal (46 -  55 Tahun)",
          "Dewasa Akhir (56 - 65 Tahun)",
          "Manula (> 65 Tahun)",
        ],
        datasets: [
          {
            label: "Pasien",
            data: [
              umurs.jumlah_balita,
              umurs.jumlah_kanak_kanak,
              umurs.jumlah_remaja_awal,
              umurs.jumlah_remaja_akhir,
              umurs.jumlah_dewasa_awal,
              umurs.jumlah_dewasa_akhir,
              umurs.jumlah_lansia_awal,
              umurs.jumlah_lansia_akhir,
              umurs.jumlah_manula,
            ],
            persen: [
              umurs.jumlah_persen_balita,
              umurs.jumlah_persen_kanak_kanak,
              umurs.jumlah_persen_remaja_awal,
              umurs.jumlah_persen_remaja_akhir,
              umurs.jumlah_persen_dewasa_awal,
              umurs.jumlah_persen_dewasa_akhir,
              umurs.jumlah_persen_lansia_awal,
              umurs.jumlah_persen_lansia_akhir,
              umurs.jumlah_persen_manula,
            ],
          },
        ],
      };
    },
    async get_kunjungan() {
      let vm = this;
      try {
        let kunjungan = await vm.$axios("rm/jumlah_kunjungan_loket");
        vm.kunjungan_loket = kunjungan.data.status == 200 ? kunjungan.data.data[0].jumlah_kunjungan_loket : 0;
        // console.log(kunjungan.data.data);
        let bpjs = await vm.$axios("kunjungan/jumlah_kunjungan_bpjs");
        vm.kunjungan_bpjs = bpjs.data.status == 200 ? bpjs.data.data[0].jumlah_kunjungan_bpjs : 0;
        // console.log(bpjs.data.data);
        let bulan_ini = await vm.$axios("kunjungan/jumlah_kunjungan_bulan_ini");
        vm.kunjungan_bulan_ini = bulan_ini.data.status == 200 ? bulan_ini.data.data[0].jumlah_kunjungan_bulan_ini : 0;
        // console.log(bulan_ini.data.data, "iki");
        let terdaftar = await vm.$axios("rm/jumlah_pasien_terdaftar");
        // console.log(terdaftar.data.data, 'pasien')
        vm.pasien_terdaftar = terdaftar.data.status == 200 ? terdaftar.data.data[0].jumlah_pasien_terdaftar : 0;
        let baru = await vm.$axios("rm/jumlah_pasien_baru");
        // console.log(baru.data.data, 'pasien')
        vm.pasien_baru = baru.data.status == 200 ? baru.data.data[0].jumlah_pasien_baru : 0;
      } catch (err) {
        console.log(err);
      }
    },
    async get_pengguna() {
      let vm = this;
      try {
        let pengguna = await vm.$axios("user/total_pengguna");
        console.log(pengguna.data.data, "pengguna");
        vm.pengguna = pengguna.data.status == 200 ? pengguna.data.data[0] : 0;
        let dataPengguna = {
          labels: [],
          datasets: [
            {
              label: "Pengguna",
              data: [],
            },
          ],
        };
        vm.pengguna = pengguna.data.status == 200 ? pengguna.data.data : [];
        vm.pengguna.map((item) => {
          dataPengguna.labels.push(item.tahun);
          dataPengguna.datasets[0].data.push(item.jumlah_pengguna);
        });
        vm.dataPengguna = dataPengguna;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_asuransi() {
      let vm = this;
      try {
        let asuransi = await vm.$axios.post("kunjungan/kunjungan_berdasarkan_asuransi");
        // console.log(asuransi.data.data, "asuransi");
        let dataAsuransi = {
          labels: [],
          datasets: [
            {
              label: "Umum",
              data: [],
            },
            {
              label: "BPJS",
              data: [],
            },
            {
              label: "Lain-lain",
              data: [],
            },
          ],
        };
        vm.kunjungan_asuransi = asuransi.data.status == 200 ? asuransi.data.data : [];
        vm.kunjungan_asuransi.map((item) => {
          dataAsuransi.labels.push(item.bulan.toUpperCase());
          dataAsuransi.datasets[0].data.push(item.jumlah_umum);
          dataAsuransi.datasets[1].data.push(item.jumlah_bpjs);
          dataAsuransi.datasets[2].data.push(item.jumlah_lainnya);
        });
        vm.dataAsuransi = dataAsuransi;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_jenis() {
      let vm = this;
      try {
        let jenis = await vm.$axios.post("kunjungan/kunjungan_berdasarkan_jenis");
        // console.log(jenis.data.data, "jenis");
        let dataJenis = {
          labels: [],
          datasets: [
            {
              label: "Baru",
              data: [],
            },
            {
              label: "Lama",
              data: [],
            },
          ],
        };
        vm.kunjungan_jenis = jenis.data.status == 200 ? jenis.data.data : [];
        vm.kunjungan_jenis.map((item) => {
          dataJenis.labels.push(item.bulan.toUpperCase());
          dataJenis.datasets[0].data.push(item.jumlah_kunjungan_lama);
          dataJenis.datasets[1].data.push(item.jumlah_kunjungan_baru);
        });
        vm.dataJenis = dataJenis;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_harian() {
      let vm = this;
      try {
        let tahun = await vm.$axios("kunjungan/kunjungan_per_tanggal_selama_satu_bulan");
        // console.log(tahun.data.data, "tahun");
        let dataTahun = {
          labels: [],
          datasets: [
            {
              label: "Kunjungan",
              data: [],
            },
          ],
        };
        vm.kunjungan_tahun = tahun.data.status == 200 ? tahun.data.data : [];
        vm.kunjungan_tahun.map((item) => {
          dataTahun.labels.push(item.tanggal);
          dataTahun.datasets[0].data.push(item.jumlah);
        });
        vm.dataTahun = dataTahun;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_poli() {
      let vm = this;
      try {
        let poli = await vm.$axios("kunjungan/kunjungan_berdasarkan_poli");
        // console.log(poli.data.data, "poli");
        let dataPoli = {
          labels: [],
          datasets: [
            {
              label: "Kunjungan",
              data: [],
            },
          ],
        };
        vm.kunjungan_poli = poli.data.status == 200 ? poli.data.data : [];
        vm.kunjungan_poli.map((item) => {
          dataPoli.labels.push(item.nama_poli);
          dataPoli.datasets[0].data.push(item.jumlah_kunjungan);
        });
        vm.dataPoli = dataPoli;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_faskes() {
      let vm = this;
      try {
        let faskes = await vm.$axios("kunjungan/kunjungan_berdasarkan_faskes");
        // console.log(faskes.data.data, "faskes");
        let dataFaskes = {
          labels: [],
          datasets: [
            {
              label: "Kunjungan",
              data: [],
            },
          ],
        };
        vm.kunjungan_faskes = faskes.data.status == 200 ? faskes.data.data : [];
        vm.kunjungan_faskes.map((item) => {
          dataFaskes.labels.push(item.nama_puskesmas.toUpperCase());
          dataFaskes.datasets[0].data.push(item.jumlah_kunjungan);
        });
        vm.dataFaskes = dataFaskes;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_wilayah() {
      let vm = this;
      try {
        let wilayah = await vm.$axios("kunjungan/kunjungan_berdasarkan_wilayah");
        // console.log(wilayah.data.data, "Wilayah");

        let dataWilayah = {
          labels: [],
          datasets: [
            {
              label: "Kunjungan",
              data: [],
            },
          ],
        };
        vm.kunjungan_wilayah = wilayah.data.status == 200 ? wilayah.data.data : [];
        vm.kunjungan_wilayah.map((item) => {
          dataWilayah.labels.push(item.nama_kecamatan.toUpperCase());
          dataWilayah.datasets[0].data.push(item.jumlah_kunjungan);
        });
        vm.dataWilayah = dataWilayah;
      } catch (err) {
        console.log(err);
      }
    },
    async get_diagnosa() {
      let vm = this;
      try {
        let diagnosa = await vm.$axios("pool_diagnosa/kasus_diagnosa_terbanyak");
        // console.log(diagnosa.data.data, "diagnosa");
        vm.diagnosa =
          diagnosa.data.status == 200
            ? diagnosa.data.data.filter((item, idx) => {
                item.no = idx + 1;
                return idx < 10;
              })
            : [];
        vm.diagnosaFields[4].key = vm.$moment().format("MM");
        vm.diagnosaFields[4].label = vm.$moment().format("MMM");
        vm.diagnosaFields[3].key = vm.$moment().subtract(1, "month").format("MM");
        vm.diagnosaFields[3].label = vm.$moment().subtract(1, "month").format("MMM");
        // console.log(vm.diagnosaFields);
      } catch (err) {
        console.log(err);
      }
    },
    async get_obat() {
      let vm = this;
      try {
        let obat = await vm.$axios("obat_jual/penggunaan_obat_terbanyak");
        // console.log(obat.data.data, "obat");
        vm.obat =
          obat.data.status == 200
            ? obat.data.data.filter((item, idx) => {
                item.no = idx + 1;
                return idx < 10;
              })
            : [];
        vm.obatFields[3].key = vm.$moment().format("MM");
        vm.obatFields[3].label = vm.$moment().format("MMM");
        vm.obatFields[2].key = vm.$moment().subtract(1, "month").format("MM");
        vm.obatFields[2].label = vm.$moment().subtract(1, "month").format("MMM");
        // console.log(vm.obatFields);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

body {
  font-family: "poppins";
  font-size: var(--font-size);
}
.bg-primary {
  background-color: #2ecc71 !important;
}

.display-card {
  color: white;
}
</style>
