<template>
  <div class="card p-3">
    <h5 class="custom-title-card">
      <strong>{{ judul }}</strong>
    </h5>
    <canvas id="myChart" ref="bargrid" style="height: 400px; width: 100%"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  // components: {
  //   Chart,
  // },
  props: ["datasetx", "judul", "backgroundColor"],
  data() {
    return {
      ctx: null,
      myChart: null,
      labels: ["2019", "2020", "2021", "2022", "2023"],
      datasetsx: [780, 220, 210, 500, 1200],
      render: false,
      showChart: false,
      dataset: [],
      horizontalOptions: {
        indexAxis: "y",
        responsive: true,
        title: {
          display: true,
          text: "Grafik Mahasiswa",
        },
        tooltips: {
          mode: "index",
          intersect: true,
        },
        annotation: {
          annotations: [
            {
              type: "line",
              // mode: 'horizontal',
              scaleID: "y-axis-0",
              value: 5,
              borderColor: "rgb(75, 192, 192)",
              borderWidth: 4,
              label: {
                enabled: false,
                content: "Test label",
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    const vm = this;
    // vm.getData();
    vm.ctx = this.$refs.bargrid.getContext("2d");
    vm.myChart = new Chart(vm.ctx, {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: null,
            data: [],
            backgroundColor: vm.backgroundColor,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        tension: 0.3,
        plugins: {
          legend: {
            display: true,
          },
          filler: {
            propagate: true,
          },
        },
      },
    });
    // vm.myChart.update();
    vm.reset();
  },
  computed: {
    height() {
      let vm = this;
      if (vm.dataset.length == 1) {
        return 50;
      } else if (vm.dataset.length <= 6) {
        return vm.dataset.length * 30;
      } else if (vm.dataset.length <= 15) {
        return vm.dataset.length * 25;
      } else {
        return vm.dataset.length * 20;
      }
    },
  },
  watch: {
    datasetx: function (newV, oldV) {
      // console.log(newV, "datasetx");
      if (newV != oldV) {
        this.deploy_new_dataset(newV);
      }
    },
    judul(val){
      console.log(val)
    }
  },
  methods: {
    async deploy_new_dataset(data) {
      let vm = this;
      // console.log(vm.datasetx);
      let x = data.datasets;
      vm.myChart.data.labels = data.labels;
      vm.myChart.data.datasets = await x.map((item, idx) => {
        let factor = 1 / (vm.backgroundColor.length - 1);
        let gradient = vm.ctx.createLinearGradient(0, 0, 0, 777);
        for (let i = 0; i < vm.backgroundColor.length; i++) {
          gradient.addColorStop(0 + i * factor, vm.backgroundColor[idx][i]);
        }
        item.backgroundColor = gradient;
        item.borderColor = vm.backgroundColor[idx];
        item.fill = true;
        item.hoverOffset = 4;
        return item;
      });
      vm.myChart.update();
    },
    reset() {
      let vm = this;
      vm.deploy_new_dataset(vm.datasetx);
      vm.myChart.update();
    },
    async refreshChart() {
      const vm = this;
      vm.showChart = false;
      // let result = await vm.$axiossimadu.post('registrasiMahasiswa/jumlahMahasiswa', {
      //     prodi_id: vm.prodi_id,
      //     tahun_ajaran_id: vm.tahun_ajaran_id,
      //     semester_id: vm.semester_id,
      //     fakultas_id: vm.fakultas_id,
      // });
      // vm.dataset = result.data.data;
      // console.log(result.data.data, 'chart');
      setTimeout(() => {
        // let chart = this.primeChart;
        // console.log( chart, 'prime');
        // chart.reinit(this.dataChart, this.horizontalOptions);
        // chart.refresh()
        this.showChart = true;
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
