<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <hr />
            </b-col>
          </b-row>

          <b-alert dismissible fade :show="showing" :variant="variant">{{ msg }}</b-alert>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="dataset"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
                :busy="tableBusy"
              >
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {},
  name: "obat_laris",
  props:['dataset','fields'],
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      listKomposisiBarang: [],
      listJenisAlergi: [],
      items: [
        {
          no: 1,
          nama: "Paracetamol 500 Mg",
          kode: "J06",
          okt: 4899,
          nov: 422,
        },
        { no: 2, nama: "Antasida Doen", kode: "K30", okt: 4899, nov: 422 },
        {
          no: 3,
          nama: "Fe",
          kode: "J06.9",
          okt: 4899,
          nov: 422,
        },
        { no: 4, nama: "Essential", kode: "I10", okt: 4899, nov: 422 },
        {
          no: 5,
          nama: "Essential (Primary) Hypertension",
          kode: "K30",
          okt: 4899,
          nov: 422,
        },
        { no: 6, nama: "SCOPMA PLUS", kode: "JK31", okt: 4899, nov: 422 },
        { no: 7, nama: "Paracetamol 600 Mg", kode: "JK1", okt: 4899, nov: 422 },
        { no: 8, nama: "Paracetamol 700 Mg", kode: "J22", okt: 4899, nov: 422 },
        { no: 9, nama: "Paracetamol 800 Mg", kode: "J20", okt: 4899, nov: 422 },
        { no: 10, nama: "Paracetamol 1000 Mg", kode: "J26", okt: 0, nov: 77 },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
    role() {
      return this.$store.state.user_role;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
